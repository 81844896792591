<template>
  <div class="fangan">
    <banner />
    <service />
    <tongdao />
    <yingshou />
    <jingying />
    <dayy />
    <youshiuse />
  </div>
</template>

<script>
import Banner from "./childrenCpn/Banner";
import Service from "./childrenCpn/Service";
import Tongdao from "./childrenCpn/Tongdao";
import Yingshou from "./childrenCpn/Yingshou";
import Jingying from './childrenCpn/Jingying';
import Dayy from './childrenCpn/Dayy';
import Youshiuse from './childrenCpn/Youshiuse';

export default {
  name: "Fangan",
  components: {
    Banner,
    Service,
    Tongdao,
    Yingshou,
    Jingying,
    Dayy,
    Youshiuse
  },
};
</script>

<style scoped>
.fangan {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>
