<template>
  <div class="dayy">
    <div class="title">大医云系统</div>
    <p class="subtitle">
      是业务流程、经营管理的系统、更是服务提升、运营推广的方案
    </p>
    <div class="clearfix">
      <div class="zs"></div>
      <div class="llg"></div>
      <div class="ls"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dayy",
};
</script>

<style scoped>
.dayy {
  padding: 80px 0;
}

.title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 24px;
}

.subtitle {
  font-size: 20px;
  color: #656565;
  text-align: center;
  line-height: 32px;
}

.clearfix {
  display: flex;
  justify-content: center;
  width: 84.5%;
  margin: 80px auto 0;
  min-width: 1220px;
}

.clearfix div {
  height: 338px;
  width: 450px;
  background-size: 100%;
}

.zs {
  background: url(~assets/img/fangan/dayy-zhensuo.png) no-repeat;
}

.llg {
  background: url(~assets/img/fangan/dayy-liliaoguan.png) no-repeat;
  margin-left: 60px;
}

.ls {
  background: url(~assets/img/fangan/dayy-liansuo.png) no-repeat;
  margin-left: 60px;
}
</style>
