<template>
  <div class="tongdao"></div>
</template>

<script>
export default {
  name: "Tongdao",
};
</script>

<style scoped>
.tongdao {
  height: 742px;
  width: 100%;
  background: url(~assets/img/fangan/bg-tongdao.png) no-repeat center;
  background-size: 1141px 738px;
}
</style>
