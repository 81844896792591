<template>
  <div class="banner">
    <div class="banner-top"></div>
    <div class="banner-bottom"></div>
    <div class="xctg">
      <h3>宣传推广</h3>
      <ul>
        <li style="width: 53%; border-right: 1px solid #999; padding-left: 5%">
          <h4>为您的线下中医单位</h4>
          <p>一、打造独立入口：PC网站 + 手机网站 + 微信网站</p>
          <p style="margin-left: 35px">连接微信服务：微信挂号 + 微信支付</p>
          <p>二、挖掘医馆特色，构建智能化库：宣传库、答疑库、产品库。</p>
          <p style="margin-left: 35px">聚合中医平台为您推广，让更多人了解您。</p>
        </li>
        <li>
          <h4>特色</h4>
          <p>不仅仅为您建造宣传站，我们还为您精准推广。</p>
          <p>全新的宣传模式，从现在开始（点击这里）</p>
          <span class="spBtn">
            <i> <img src="~assets/img/fangan/ico.png" alt=""/> </i>
            <a href="http://www.sktcm.com/weidian/Clinic/Ygregister/index.html"><div class="apybtn">申请使用</div></a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Banner",
    methods: {
      
    }
  }
</script>

<style scoped>
  .banner {
    height: 945px;
    position: relative;
    /* width: 100%; */
  }

  .banner-top {
    background: url("~assets/img/fangan/index-banner.png") top center no-repeat;
    height: 781px;
    min-width: 1220px;
    /* width: 100%; */
  }

  .banner-bottom {
    height: 165px;
    background: #9f9f9f;
    /* min-width: 1220px; */
    /* width: 100%; */
  }

  .xctg {
    width: 1183px;
    top: 632px;
    position: absolute;
    color: #666;
    background: #fff;
    border-radius: 8px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .xctg h3 {
    font-weight: normal;
    position: absolute;
    top: 35px;
    left: 35px;
    background: url("~assets/img/fangan/titbg.jpg") no-repeat;
    width: 50px;
    height: 171px;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    padding-top: 5px;
  }

  .xctg ul {
    margin-left: 55px;
    overflow: hidden;
    padding: 20px 0;
  }

  .xctg li {
    float: left;
    padding: 0 0 0 4%;
    line-height: 32px;
  }

  .xctg li h4 {
    font-weight: normal;
    font-size: 24px;
    padding-bottom: 6px;
  }

  .xctg li p {
    margin-top: 10px;
    font-size: 18px;
  }

  .spBtn {
    display: block;
    text-align: right;
    margin-top: 28px;
  }

  .spBtn i {
    vertical-align: top;
    display: inline-block;
    margin-right: 30px;
  }

  .apybtn {
    width: 190px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 16px;
    background: #c15d3e;
    display: inline-block;
    border-radius: 4px;
  }
</style>
