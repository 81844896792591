<template>
  <div class="service">
    <h2>为医馆提供管理系统、运营方案服务</h2>
    <div class="subtitle">
      ----------
      <span></span>
      <span class="texts">规范业务流程</span>
      <span></span>
      ----------
    </div>
    <div class="liucheng" style="margin-bottom: 100px">
      <div class="line">
        <div class="item">
          <img src="~assets/img/fangan/yuyueguahao.png" alt="" />
          <p>预约挂号</p>
        </div>
        <div class="arrow-right">
          <img src="~assets/img/fangan/icon-arrow.png" alt="" />
        </div>
        <div class="item">
          <img src="~assets/img/fangan/quhaohouzhen.png" alt="" />
          <p>取号候诊</p>
        </div>
        <div class="arrow-right">
          <img src="~assets/img/fangan/icon-arrow.png" alt="" />
        </div>
        <div>
          <div class="item">
            <img src="~assets/img/fangan/zhenqiansizhen.png" alt="" />
            <p>诊前四诊</p>
          </div>
          <div><img src="~assets/img/fangan/icon-arrow2.png" alt="" /></div>
        </div>
      </div>
      <div class="line">
        <div>
          <div class="item">
            <img src="~assets/img/fangan/zhiliaoshishi.png" alt="" />
            <p>治疗实施/抓药煎药</p>
          </div>
          <div><img src="~assets/img/fangan/icon-arrow2.png" alt="" /></div>
        </div>
        <div class="arrow-right">
          <img src="~assets/img/fangan/icon-arrow3.png" alt="" />
        </div>
        <div class="item">
          <img src="~assets/img/fangan/jiezhangshoufei.png" alt="" />
          <p>结账收费</p>
        </div>
        <div class="arrow-right">
          <img src="~assets/img/fangan/icon-arrow3.png" alt="" />
        </div>
        <div class="item">
          <img src="~assets/img/fangan/jiuzhenkaifang.png" alt="" />
          <p>就诊开方</p>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <img src="~assets/img/fangan/fayaokuaidi.png" alt="" />
          <p>发药快递</p>
        </div>
        <div class="arrow-right">
          <img src="~assets/img/fangan/icon-arrow.png" alt="" />
        </div>
        <div class="item">
          <img src="~assets/img/fangan/jiuzhenjieshu.png" alt="" />
          <p>就诊结束</p>
        </div>
        <div class="arrow-right">
          <img src="~assets/img/fangan/icon-arrow.png" alt="" />
        </div>
        <div class="item">
          <img src="~assets/img/fangan/fankuisuifang.png" alt="" />
          <p>反馈随访</p>
        </div>
      </div>
    </div>
    <div class="subtitle">
      ----------
      <span></span>
      <span class="texts">规范经营管理</span>
      <span></span>
      ----------
    </div>
    <div class="jingying" style="padding: 0 30px">
      <div class="item2">
        <img src="~assets/img/fangan/jingying-paiban.png" alt="" />
        <p style="margin-top: 22px">排班</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-bingli.png" alt="" />
        <p style="margin-top: 22px">病例</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-yian.png" alt="" />
        <p style="margin-top: 22px">医案</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-huiyuan.png" alt="" />
        <p style="margin-top: 22px">会员</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-yaofangerp.png" alt="" />
        <p style="margin-top: 22px">药房ERP</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-caiwuduizhang.png" alt="" />
        <p style="margin-top: 22px">财务对账</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-yewutongji.png" alt="" />
        <p style="margin-top: 22px">业务统计</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-kehucrm.png" alt="" />
        <p style="margin-top: 22px">客户CRM</p>
      </div>
      <div class="item2">
        <img src="~assets/img/fangan/jingying-liansuojingying.png" alt="" />
        <p style="margin-top: 22px">连锁经营</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Service",
};
</script>

<style scoped>
.service {
  background: url(~assets/img/fangan/index-fangan-bg.png) top center no-repeat;
  padding: 80px 0;
}

.service h2 {
  font-weight: normal;
  font-size: 36px;
  text-align: center;
}

.subtitle {
  font-size: 24px;
  text-align: center;
  margin-bottom: 100px;
  margin-top: 30px;
  color: #666666;
}

.subtitle .texts {
  font-size: 24px;
  margin: 0 5px;
}

.subtitle span:first-child {
  width: 15px;
  height: 19px;
  display: inline-block;
  background-image: url(~assets/img/fangan/icon-rec.png);
  background-repeat: no-repeat;
  background-position: -2px 2px;
}

.subtitle span:last-child {
  width: 15px;
  height: 19px;
  display: inline-block;
  background-image: url(~assets/img/fangan/icon-rec.png);
  background-repeat: no-repeat;
  background-position: 0 2px;
}

.line {
  display: flex;
  justify-content: center;
}

.item {
  width: 287px;
  height: 189px;
  background: #fff;
  text-align: center;
  font-size: 18px;
  border-radius: 3px;
  box-shadow: 2px 2px 1px #e2e2e2;
  margin: 10px 0;
}

.item img {
  margin: 40px 0 30px;
}

.arrow-right {
  height: 189px;
  display: flex;
  margin: 0 15px;
}

.arrow-right img {
  align-self: center;
}

.jingying {
  display: flex;
  justify-content: center;
}

.item2 {
  width: 100px;
  height: 93px;
  float: left;
  font-size: 18px;
  color: #5b5b5b;
  margin: 0 20px;
}
</style>
